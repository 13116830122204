import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./account/login/Login";
import Main from "./main/Main";
import SideBar from "./common/SideBar";
import ForumMain from "./forum/pages/ForumMain";
import UserPage from "./user-page/UserPage";
import ForumDetail from "./forum/pages/ForumDetail";
import JoinMember from "./admin/JoinMember";
import Join from "./account/join/Join";
import ForumCreate from "./forum/pages/ForumCreate";
// import PCMainPublic from "./main/PC/PCMainPublic";
import NotFound from "./common/NotFound";
import MobilePublicMain from "./main/Mobile/mobilePublic/MobilePublicMain";


import Header from './bu/pages/seopia/header/Header';
import StartPage from './bu/pages/seopia/startpage/StartPage';
import P63 from './bu/pages/seopia/p63/P63';
import Result from './bu/pages/seopia/resultpage/Result';
import P58 from './bu/pages/seopia/p58/P58';
import P58a from './bu/pages/seopia/p58_1/P58a';
import P59 from "./bu/pages/jeahee/P59/P59";
import P60 from "./bu/pages/jeahee/P60/P60";
import P61 from './bu/pages/Doyeon/P61/P61';
import P62 from './bu/pages/Doyeon/P62/P62';
import P64 from './bu/pages/Doyeon/P64/P64';
import Choose from "./bu/pages/Choose";


function App() {
  return (
    <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true}}>
      <Routes>
        <Route path="/" element={<Choose/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/join" element={<Join/>}/>
        <Route element={<SideBar/>}>
          <Route path="main" element={<Main/>}/>
          <Route path="/main/public" element={<MobilePublicMain/>}/>
          <Route path="user/:id" element={<UserPage/>}/>
          <Route path="admin" element={<JoinMember/>}/>
          <Route path="/forum" element={<ForumMain/>} />
          <Route path="/forum/:code" element={<ForumDetail/>} />
          <Route path="/forum/write" element={<ForumCreate />} />
        </Route>
        <Route element={<Header/>}>
          <Route path="/bu/" element={<StartPage/>}/>
          <Route path='/bu/p58' element={<P58/>}/>
          <Route path='/bu/p58a' element={<P58a/>}/>
          <Route path="/bu/p59" element={<P59/>}/>
          <Route path="/bu/p60" element={<P60/>}/>
          <Route path='/bu/P61' element={<P61/>}/>
          <Route path='/bu/P62' element={<P62/>}/>
          <Route path='/bu/p63' element={<P63/>}/>
          <Route path='/bu/P64' element={<P64/>}/>
          <Route path='/bu/result' element={<Result/>}/>
        </Route>
        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
